export function addBusinessDays(days) {
  const date = new Date();
  const d = new Date(date.getTime());
  const day = d.getDay();
  const final = d.setDate(d.getDate() + days + (day === 6 ? 2 : +!day) + (Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2));
  const f = new Date(final);
  const month = f.toLocaleString('default', { month: 'long' });

  const x = f.getDate();
  return `${month} ${x}`;
}

export function isCurrentDateGreater(dateToCompare) {
  const currentDate = Date.now();
  const formattedDateToCompare = new Date(dateToCompare);

  return currentDate >= formattedDateToCompare;
}

export function toMonthString(month) {
  return month.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
}
