//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import { addBusinessDays } from '~/utils/date';

export default {
  name: 'DeliveryTabShipping',
  components: {
    ShippingBlock: () => import('~/components/checkout/components/ShippingBlock.vue'),
  },
  computed: {
    ...mapGetters([
      'summary_shipping',
      'hasPackageWithBag',
      'isFreeStandingBag',
      'cartqty',
      'isShopifyOn',
      'isGQOrder',
      'isGen2UpgradeInCart',
    ]),
    ...mapState({
      shippingValidationError: state => state.checkout.shippingError,
    }),
    displayPrice() {
      if (!this.summary_shipping) return '';
      if (this.shippingValidationError) return '';

      return this.isShopifyOn && !this.isGQOrder ? this.$options.filters.currency(this.summary_shipping) : this.$options.filters.currency2(this.summary_shipping);
    },
    shippingMethod() {
      let startDay;
      let endDay;
      if (this.isGen2UpgradeInCart) {
        console.log('Gen2 Upgrade in cart');
        startDay = 23;
        endDay = 27;
      } else {
        [startDay, endDay] = this.$store.state.checkout.shippingDays;
      }
      const dates = `${addBusinessDays(startDay)} - ${addBusinessDays(endDay)}`;

      return {
        price: this.displayPrice,
        title: 'Standard Delivery',
        dates,
        showModalButton: false,
      };
    },
  },
};
